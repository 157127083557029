<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => props.accountId);
</script>
<template>
  <a
    class="active-retainer-balance"
    :href="`/#/account/${accountId}/contracts`"
  >
    <div class="active-retainer-balance__icon-plus-amount">
      <div class="active-retainer-balance__icon-container">
        <SoonaIcon class="dollar-icon" size="small" name="dollar-sign" />
      </div>
      <p class="u-small--regular active-retainer-balance__title">
        Account balance
      </p>
    </div>
    <SoonaIcon
      class="subscription-purchase__arrow"
      name="arrow-right"
      size="small"
    />
  </a>
</template>
<style lang="scss" scoped>
@use '@/variables';

.active-retainer-balance {
  align-items: center;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;

  &__title {
    align-self: center;
  }

  &__icon-plus-amount {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  &__icon-container {
    display: inline-flex;
    gap: 0.375rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 10rem;
    cursor: pointer;
    background-color: variables.$green-apple-20;
    color: variables.$green-apple-80;
  }
}
</style>
