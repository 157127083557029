<script setup>
import SubscriptionPurchase from '@/components/platform-layout/sidebar/SubscriptionPurchase.vue';
import CreditPurchase from '@/components/platform-layout/sidebar/CreditPurchase.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';
import { computed } from 'vue';
import ActiveRetainerBalance from '@/components/platform-layout/sidebar/ActiveRetainerBalance.vue';
import { useFlag } from '@/composables/useFlag';

const { currentAccountId } = useMe();
const { account, isActiveRetainerClient } = useAccount(currentAccountId);
const paymentsRetainerMvpFlag = useFlag('payments_retainer_mvp');

const preferredCredits = computed(
  () => account.value?.preferred_credit_count || 0
);
</script>

<template>
  <div class="user-hub">
    <SubscriptionPurchase />
    <ActiveRetainerBalance
      v-if="isActiveRetainerClient && paymentsRetainerMvpFlag"
      :account-id="account.id"
    />
    <CreditPurchase :preferred-credits="preferredCredits" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.user-hub {
  background: variables.$gradient-periwink-error;
  border-radius: 0.5rem;
}
</style>
